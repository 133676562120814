import React, { useState } from 'react'
import closeMenu from '../../Images/closeMenu.png'
import next from '../../Images/next.png'
import telephone from '../../Images/telephone.png'
import book from '../../Images/book.png'
import tiktok from '../../Images/tiktok.png';
import facebook from '../../Images/facebook.png';
import instagram from '../../Images/instagram.png';
import './Menu.scss';
import $ from 'jquery';

export default function Menu({ navigateTo, setShowMenu, WebSitePages }) {

    const [showMakers, setshowMakers] = useState(false);
    return (
        <div className="Menu">
            <div className="MenuBoxShadow" onClick={() => setShowMenu(false)}></div>
            <div className="MenuList">
                <div className="MenuListTop">
                    <div className="MenuMain">
                        <div className="MenuMainBox">
                            <a href='/'>Home</a>
                        </div>
                        <div className="MenuMainBox" onClick={() => {
                            setshowMakers(true)
                        }}>
                            <h1 >Services</h1>
                            <img src={next} alt="" />
                        </div>
                        <div className="MenuMainBox">
                            <a href='/contact'>Contact Us</a>
                        </div>
                        <div className="MenuMainBox">
                            <a href='/book'>Book An Appointment</a>
                        </div>
                        <div className="MenuMainBox">
                            <a href='/blog'>Blog</a>
                        </div>
                    </div>
                    <div className="MenuFooter">
                        <h5>Copyright © 2024 Anaheim CHIMNEY SWEEP. All rights reserved.</h5>
                    </div>

                    {/* showCategories */}
                    {showMakers ?
                        <div className="MenuMakers">
                            <div className="MenuMakersHeader">
                                <img src={next} alt="" onClick={() => {
                                    setshowMakers(false)
                                }} />
                                <h1 onClick={() => {
                                    // navigateTo("")
                                }}>Services</h1>
                            </div>
                            <div className="MenuMakersList">
                                <div className="MenuMakersListBox">
                                    <a href="/service/Chimney-Cap-Repair" className="Header1BoxMenuBox">
                                        Chimney Cap Repair
                                    </a>
                                    <a href="/service/Chimney-Cleaning" className="Header1BoxMenuBox">
                                        Chimney Cleaning
                                    </a>
                                    <a href="/service/Chimney-Construction" className="Header1BoxMenuBox">
                                        Chimney Construction
                                    </a>
                                    <a href="/service/Chimney-Crown-Repair" className="Header1BoxMenuBox">
                                        Chimney Crown Repair
                                    </a>
                                    <a href="/service/Chimney-Flue-Installation" className="Header1BoxMenuBox">
                                        Chimney Flue Installation
                                    </a>
                                    <a href="/service/Chimney-Flue-Repair" className="Header1BoxMenuBox">
                                        Chimney Flue Repair
                                    </a>
                                    <a href="/service/Chimney-Inspection" className="Header1BoxMenuBox">
                                        Chimney Inspection
                                    </a>
                                    <a href="/service/Chimney-Installation" className="Header1BoxMenuBox">
                                        Chimney Installation
                                    </a>
                                    <a href="/service/Chimney-Insulation" className="Header1BoxMenuBox">
                                        Chimney Insulation
                                    </a>
                                    <a href="/service/Chimney-Cracks-Repair" className="Header1BoxMenuBox">
                                        Chimney Cracks Repair
                                    </a>
                                    <a href="/service/Chimney-Firebox-Paint" className="Header1BoxMenuBox">
                                        Chimney Firebox Paint
                                    </a>
                                    <a href="/service/Chimney-Firebox-Cleaining" className="Header1BoxMenuBox">
                                        Chimney Firebox Cleaining
                                    </a>
                                    <a href="/service/Chimney-Firebox-Inspection" className="Header1BoxMenuBox">
                                        Chimney Firebox Inspection
                                    </a>
                                    <a href="/service/Chimney-Best-Company" className="Header1BoxMenuBox">
                                        Chimney Best Company
                                    </a>
                                    <a href="/service/Firebox-Inspection" className="Header1BoxMenuBox">
                                        Firebox Inspection
                                    </a>
                                    <a href="/service/Firebox-Sweeping" className="Header1BoxMenuBox">
                                        Firebox Sweeping
                                    </a>
                                    <a href="/service/Fireplace-Inspection" className="Header1BoxMenuBox">
                                        Fireplace Inspection
                                    </a>
                                    <a href="/service/Firepalce-Repair" className="Header1BoxMenuBox">
                                        Firepalce Repair
                                    </a>
                                    <a href="/service/Chimney-Liner-Repair" className="Header1BoxMenuBox">
                                        Chimney Liner Repair
                                    </a>
                                    <a href="/service/Chimney-Maintenance" className="Header1BoxMenuBox">
                                        Chimney Maintenance
                                    </a>
                                    <a href="/service/Chimney-Pointing" className="Header1BoxMenuBox">
                                        Chimney Pointing
                                    </a>
                                    <a href="/service/Chimney-Rain-Cap-Installation" className="Header1BoxMenuBox">
                                        Chimney Rain Cap Installation
                                    </a>
                                    <a href="/service/Chimney-Repair" className="Header1BoxMenuBox">
                                        Chimney Repair
                                    </a>
                                    <a href="/service/Chimney-Restoration" className="Header1BoxMenuBox">
                                        Chimney Restoration
                                    </a>
                                    <a href="/service/Chimney-Flashing-Repair" className="Header1BoxMenuBox">
                                        Chimney Flashing Repair
                                    </a>
                                    <a href="/service/Chimney-Liner-Installation" className="Header1BoxMenuBox">
                                        Chimney Liner Installation
                                    </a>
                                    <a href="/service/Chimney-Damper-Repair" className="Header1BoxMenuBox">
                                        Chimney Damper Repair
                                    </a>
                                </div>

                            </div>

                        </div>
                        : null}
                </div>
                <div className="MenuListFooter">
                    <p>
                        Monday - Sunday
                        <br />
                        8:00 a.m. - 8:00 p.m. EST
                    </p>
                    <a href="tel:951-382-5416">CALL NOW 951-382-5416</a>
                    <a href="/book">BOOK AN APPOINTMENT</a>
                </div>

            </div>
        </div>
    )
}
